import React, {useState,useEffect} from 'react'
import emailjs from 'emailjs-com';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link as ScrollLink, Events, animateScroll as scroll } from 'react-scroll';
import logo from '../images/logo2.png'

export default function Contact_us() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        contactingRegarding: '',
        description: '',
      });
      const [showPopup, setShowPopup] = useState(false);
    
      const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // You should replace these with your email service credentials
        const serviceId = 'service_3ny9amh';
        const templateId = 'template_ti1h8dt';
        const publicKey = 'bd3qAHEk9ytZa3adf';
    
        // Send the email
        emailjs.send(serviceId, templateId, formData, publicKey)
          .then((response) => {
            console.log('Email sent:', response);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                contactingRegarding: '',
                description: '',
              })
              setShowPopup(true);
          })
          .catch((error) => {
            console.error('Email failed to send:', error);
          });
      };

    useEffect(() => {
        AOS.init({
          duration: 900, // You can adjust the duration as needed
          once: true, // Set to true if you want the animation to occur only once
        });
      }, []);


  return (
    <div>
    <div className='h-screen flex flex-col items-center text-center p-4 justify-center' data-aos="fade-up">
      <h1 className='font-anton text-3xl md:text-7xl border-b-4 pt-20 pb-4 md:pb-10 md:w-7/12 border-[#3466AA] uppercase '>
        Get in Touch - Your Inquiry Matters to Us!
      </h1>
      <div className='text-center text-[#3466AA]  flex justify-center pt-4 md:pt-10 pb-10'>
      <p className='font-poppins pb-4 md:w-7/12 md:font-light text-xs md:text-base text-justify-centre '>
        We're grateful for your interest in DeltaQ! Your efforts to reach out mean a lot to us. Whether you have questions about precision HVAC, refrigerated transport, or any cooling solution, we appreciate the opportunity to assist. Our team is ready to provide tailored excellence. Thank you for considering DeltaQ—your partner in cooling solutions.
      </p>
    </div>
    <ScrollLink to="mail" smooth={true} duration={900}>
          <button className="bg-[#3466AA] hover:opacity-75 hover:scale-105 transition-all duration-500 md:w-52 w-40 text-[#f2f3f4] md:text-lg mt-10 font-poppins tracking-widest p-2">
            Contact Us
          </button>
        </ScrollLink>      
    </div>
    <form onSubmit={handleSubmit} className='p-0 m-0 w-full  bg-transparent border-0'>
    <div id="mail" className='bg-[#EFF1F3] w-full md:flex justify-between font-poppins py-10  md:px-40 md:py-24'>
    <div className='md:flex p-4 md:w-1/2' >
        <div className='flex flex-col w-full space-y-5 md:space-y-10'>
            <div className='p-4 md:hidden '>
            <img
                className='w-full h-full object-cover'
                src={logo}
                alt='Image 1'
            />
        </div>
            <div className='md:flex w-full space-y-5 md:space-y-0 md:space-x-3'>
            <div className='md:w-1/2'>
                <h3 className='tracking-wider'>First Name</h3>
                <label htmlFor="firstName" className='text-gray-200'></label>
                <input
              required

                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder='your first name...'
                    className='w-full  italic h-full p-1 bg-[#EFF1F3] border-b-2 focus:border-4 border-[#3466AA]'
                />
            </div>
            <div className=' md:w-1/2'>
                <h3 className='tracking-wider'>Last Name</h3>
                <label htmlFor="lastName" className='text-gray-200'></label>
                <input
                    required
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder='your last name...'
                    className='w-full  italic  h-full p-1 bg-[#EFF1F3] border-b-2 border-[#3466AA]'
                />
            </div>
            </div>
            <div className='md:flex w-full space-y-5 md:space-y-0 md:space-x-3'>
            <div className='md:w-1/2'>
                <h3 className='tracking-wider'>Email</h3>
                <label htmlFor="email" className='text-gray-200'></label>
                <input
                    required
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='your email...'
                    className='w-full italic  h-full p-1 bg-[#EFF1F3] border-b-2 border-[#3466AA]'
                />
            </div>
            <div className='md:w-1/2'>
                <h3 className='tracking-wider'>Phone Number</h3>
                <label htmlFor="phoneNumber" className='text-gray-200'></label>
                <input
                    required
                    type="text"
                    id="phoneNumber"
                    
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder='your phone...'
                    className='w-full h-full p-1  bg-[#EFF1F3] border-b-2 italic border-[#3466AA]'
                />
            </div>
            </div>
            <div className=''>
                <h3 className='tracking-wider'>Contacting Regarding</h3>
                <label htmlFor="contactingRegarding" className='text-gray-200'></label>
                <select
                required
                    type="text"
                    id="contactingRegarding"
                    value={formData.contactingRegarding}
                    onChange={handleChange}
                    placeholder='Request'
                    className='w-full h-full bg-[#EFF1F3] text-gray-400 italic  border-b-2 border-[#3466AA]'
                >
                    <option value='none' className='text-[#114084] '>Select request...</option>
                    <option value='Cold Rooms' className='text-[#114084] '>Cold Rooms</option>
                    <option value='HVAC Revamp' className='text-[#114084] '>HVAC Revamp</option>
                    <option value='Tailored Cooling Solutions' className='text-[#114084] '>Tailored Cooling Solutions</option>
                    <option value='Refrigerated Trucks' className='text-[#114084] '>Refrigerated Trucks</option>
                    <option value='Refrigerated Solutions for Last Mile Delivery' className='text-[#114084] '>Refrigerated Solutions for Last Mile Delivery</option>
                    <option value='Bulk Milk Coolers' className='text-[#114084] '>Bulk Milk Coolers</option>
                    <option value='Freon Chillers' className='text-[#114084] '>Freon Chillers</option>
</select>
            </div>
            <div className=''>
                <h3 className='tracking-wider pb-2'>Description</h3>
                <label htmlFor="description" className='text-gray-200'></label>
                <textarea
                required
                    type="text"
                    id="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder='Your message Here'
                    className='w-full h-60 border-2 italic border-[#3466AA88] '
                />
            </div>
            <button type="submit" className="bg-[#3466AA] text-white py-2 px-4 rounded">
        Submit
      </button>
        </div>

    </div>
        <div className='md:block hidden w-1/2 p-40 px-20'>
            <img
                className='w-full h-full object-cover'
                src={logo}
                alt='Image 1'
            />
        </div>
</div>

    </form>
    {showPopup && (
          <div className="fixed inset-0 px-2 bg-black text-center bg-opacity-50 flex items-center font-poppins justify-center">
            <div className="bg-white p-8 rounded-md">
              <p className="text-xl uppercase font-bold mb-8">Request Sent!</p>
              <p className="text-gray-800 mb-4">Thank you for reaching out. We will get back to you soon.</p>
              <button className="mt-4 bg-[#3466AA] text-white py-2 w-full rounded" onClick={() => setShowPopup(false)}>
                Close
              </button>
            </div>
          </div>
        )}

    



      </div>
  )
}

