import React, {useState,useEffect} from 'react'
import { Link as ScrollLink, Events, animateScroll as scroll } from 'react-scroll';
import SliderDots from '../components/sliderDots';
import "../style/slider.css"
import "../style/main.css"
import img_main from '../images/image about_us.png';
import logo from '../images/logo2.png'
import s1 from '../images/services/services1.png'
import s2 from '../images/services/services2.png'
import s3 from '../images/services/services3.png'
import s4 from '../images/services/services4.png'
import s5 from '../images/services/services5.png'
import s6 from '../images/services/services6.png'
import s7 from '../images/services/services7.png'
import AOS from 'aos'
import 'aos/dist/aos.css'


export default function Services() {

  const[slide,setSlide]=useState(0);
  const [screenWidth, setScreenWidth] = useState(calculateInitialWidth);

  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(calculateInitialWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calculateInitialWidth() {
    // Check if it's a mobile device
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    // Return screenWidth - 40 for mobile, otherwise use the original calculation
    return isMobile ? window.innerWidth - 88 : 2*(window.innerWidth - (40 * 8))/3;
  }
  
  
  const slideContent=[
    {
      img: s1,
      h: "Cold Room and HVAC Revamp",
      desc: "HVAC precision, cold room efficiency, and tailored enhancements for industrial settings with precision temperature control."
    },
    {
      img: s2,
      h: "Refrigerated Trucks",
      desc: "Refrigerated trucks for reliable perishable cargo transport, ensuring precise cooling throughout the delivery journey."
    },
    {
      img: s3,
      h: "Refrigerated Solutions for Last Mile Delivery",
      desc: "Tailored refrigerated transport solutions for last-mile delivery, preserving temperature-sensitive goods during the final delivery leg."
    },
    {
      img: s4,
      h: "Heat Pumps for Swimming Pools",
      desc: "Energy-efficient heat pumps for pools, maintaining optimal water temperature for an enjoyable swimming experience."
    },
    {
      img: s5,
      h: "Bulk Milk Coolers",
      desc: "Efficient dairy cooling with Bulk Milk Coolers (MBC) in various capacities (250L, 500L, 750L), catering to diverse production needs."
    },
    {
      img: s6,
      h: "Freon Chillers",
      desc: "Efficient dairy cooling with Bulk Milk Coolers (MBC) in various capacities (250L, 500L, 750L), catering to diverse production needs."
    },
    {
      img: s7,
      h: "Tailored Cooling Solutions",
      desc: "Customized solutions for flexible and efficient cooling in diverse industrial settings."
    }
  ]
  const handleNextSlide=()=>{
    const isMobile = window.innerWidth <= 768;
    if (isMobile) setSlide((prev)=>((prev+1)%7))
    else setSlide((prev)=>((prev+1)%5))
  }
  const handlePrevSlide=()=>{
    const isMobile = window.innerWidth <= 768;
    if (isMobile) setSlide((prev)=>(((prev-1)+7)%7))
    else setSlide((prev)=>(((prev-1)+5)%5))
  }
  const handleDotClick = (index) => {
    setSlide(index);
  };

  useEffect(() => {
    AOS.init({
      duration: 900, // You can adjust the duration as needed
      once: true, // Set to true if you want the animation to occur only once
    });
  }, []);


  return (
    <div className='md:p-0'>
      <div className='text-center w-full flex justify-center h-screen p-4  flex-col items-center' data-aos="fade-up">
        <h1 className='font-anton text-3xl md:text-7xl border-b-4  pt-20 pb-4 md:pb-10 md:w-7/12 border-[#3466AA] uppercase '>Tailored Cooling Solutions Designed Just for You</h1>
        <div className='text-center text-[#3466AA]  flex justify-center pt-4 md:pt-10 pb-10'>
        <p className='font-poppins pb-4 md:w-9/12 md:font-light text-xs md:text-2xl text-justify-centre '>Transforming Cooling Dynamics: Uncover Tailored Cooling Solutions Redefining Industrial Efficiency.
        </p>

      </div>
      <ScrollLink to="services" smooth={true} duration={900}>
          <button className="bg-[#3466AA] hover:opacity-75 hover:scale-105 transition-all duration-500 md:w-52 w-40 text-[#f2f3f4] md:text-lg mt-10 font-poppins tracking-widest p-2">
            Explore More
          </button>
        </ScrollLink>      </div>

      
<div id="services" className='bg-[#Ebecee] pt-24 py-14 pb-24 mb-24 md:mb-32' >

      <div data-aos="fade-up" >
      <div className='text-center w-full flex justify-center'>
        <h1 className='font-anton text-[#114084] md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>TAILORED COOLING SOLUTIONS</h1>
      </div>

      <div className='text-center text-black  flex justify-center pt-10 px-8 md:px-0 '>
        <p className='font-poppins font-light pb-4 md:w-7/12 md:text-base text-sm text-justify'>
        <span className="font-">DeltaQ</span> stands at the forefront of innovation, surpassing conventional standards with our cutting-edge <span className="font-semibold">cooling solutions</span>. Our commitment to excellence is underscored by our dedication to <span className="font-semibold">tailor-made solutions</span>, where we meticulously design <span className="font-semibd">cooling systems</span> that are precisely aligned with the unique needs of our clients. In breaking away from traditional tonnage constraints, <span className="font-">DeltaQ</span> specializes in the customization of machines, offering a distinctive and effective approach to <span className="font-">cooling</span> that goes beyond the ordinary.
        <br/>
        <br/>

Through strategic partnerships and an unwavering commitment to <span className="font-semibold">tailor-made innovation</span>, we assure our clients that their <span className="font-">cooling requirements</span> are not only met but consistently exceeded. At <span className="font-">DeltaQ</span>, we redefine industry norms, guaranteeing optimal efficiency and satisfaction. Our approach goes beyond providing mere solutions; it involves creating bespoke <span className="font-">cooling systems</span> that are a perfect fit for the specific demands of each project. With a focus on innovation and a <span className="font-semibold">client-centric mindset</span>, <span className="font-">DeltaQ</span> continues to push the boundaries of what is possible in the realm of <span className="font-semibold">cooling solutions</span>.</p>


      </div>
      </div>
</div>

<div className='mx-40 my-40 border-t-4 border-b-4 border-[#114084] py-14 pb-20 md:block hidden'>
<div className='flex w-full'>
  <div className='w-1/3 flex flex-col justify-top py-6 pr-14' data-aos="fade-right">
<h2 className='font-anton text-6xl leading-[70px] text-[#114084]'>
  What DeltaQ does?
  </h2>
<p className='font-poppins text-base font-light pt-10 text-[#1E1E1E]'>
DeltaQ excels in crafting bespoke cooling solutions, catering to a range of needs, from industrial installations to transport and specialized applications.
</p>
</div>
<div className=' flex items-center ' data-aos="fade-in">
<button  className='' onClick={handlePrevSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>

<div className='w-2/3  overflow-hidden' data-aos="fade-in">

        <div className={`flex transition-all duration-500`} 
  style={{
    transform: `translateX(-${slide * (screenWidth/3)}px)`,
    width: `${screenWidth*7/3}px`,
  }}>
{slideContent.map((s,i)=>(

<div key={i} className={`flex flex-col items-center  p-5 px-12  text-center justify-top `} style={{ width: `${screenWidth/3}px` }}>
            <img src={s.img} className='h-14 w-14 object-cover mb-6' alt={`carousel-1`} />
            <div className='justify-top flex flex-col '>
         <h2 className='font-poppins uppercase font-bold text-lg mb-4 text-[#114084]'>{s.h}</h2>
          <p className='font-poppins font-light text-[13px] text-[#1E1E1E]'>{s.desc}</p>
        </div>
        </div>
))
}
      </div>
      <SliderDots totalSlides={slideContent.length-2} currentSlide={slide} handleDotClick={handleDotClick} />
</div>
      <div className=' flex items-center' data-aos="fade-in">
<button  className='rotate-180' onClick={handleNextSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>


  </div>
    </div>
<div className='mx-5 text-center my-40 border-t-4 border-b-4 border-[#114084] py-10 pb-20 md:hidden block'>
<div className='w-full ' >
  <div className='flex flex-col  py-2'>
<h2 className='font-anton text-4xl pb-10 text-[#114084]'>
  What DeltaQ does?
  </h2>
<p className='font-poppins text-base font-light text-justify text-[#1E1E1E]'>
DeltaQ excels in crafting bespoke cooling solutions, catering to a range of needs, from industrial installations to transport and specialized applications.
</p>
</div>
<div className='flex'>
<div className=' flex items-center'>
<button  className='' onClick={handlePrevSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>

<div className='  overflow-hidden'>

        <div className={`flex transition-all duration-500`} 
  style={{
    transform: `translateX(-${slide * (screenWidth)}px)`,
    width: `${screenWidth*7}px`,
  }}>
{slideContent.map((s,i)=>(

<div key={i} className={`flex flex-col items-center  p-5  mt-10 justify-top `} style={{ width: `${screenWidth}px` }}>
            <img src={s.img} className='h-14 w-14 object-cover mb-6' alt={`carousel-1`} />
            <div className='justify-top flex flex-col '>
         <h2 className='font-poppins uppercase font-bold text-xl mb-4 text-[#114084]'>{s.h}</h2>
          <p className='font-poppins font-light text-sm text-[#1E1E1E]'>{s.desc}</p>
        </div>
        </div>
))
}


      </div>
      <SliderDots totalSlides={slideContent.length} currentSlide={slide} handleDotClick={handleDotClick} />

</div>
      <div className=' flex items-center'>
<button  className='rotate-180' onClick={handleNextSlide}>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.281897 7.29289C-0.108627 7.68342 -0.108627 8.31658 0.281897 8.70711L6.64586 15.0711C7.03638 15.4616 7.66955 15.4616 8.06007 15.0711C8.4506 14.6805 8.4506 14.0474 8.06007 13.6569L2.40322 8L8.06007 2.34315C8.4506 1.95262 8.4506 1.31946 8.06007 0.928932C7.66955 0.538408 7.03638 0.538408 6.64586 0.928932L0.281897 7.29289ZM23.0117 7L0.989004 7V9L23.0117 9V7Z" fill="#114084"/>
</svg>
</button>
</div>
</div>

  </div>
    </div>
<div data-aos="fade-up">
      <div className='text-center w-full flex justify-center pt-10' >
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>WHAT WE SPECIALISE IN ?</h1>
        
      </div>

      <div className='text-center text-[#3466AA]  flex justify-center pt-10 mb-24 md:mb-32'>
        <p className='font-poppins pb-4 md:w-7/12 md:text-base text-sm px-8 md:px-0  font-light text-justify'><span className="">DeltaQ</span> is a leading authority in <span className="font-semibold">Heating, Ventilation, and Air Conditioning (HVAC)</span> systems, distinguished by its commitment to delivering state-of-the-art solutions that redefine comfort and efficiency. Our expertise extends beyond conventional standards, integrating cutting-edge technologies into <span className="font-semibold">HVAC systems</span> to ensure optimal performance and energy efficiency. We take pride in crafting solutions that cater to the diverse needs of residential, commercial, and industrial spaces, creating environments that prioritize indoor air quality and comfort. <span className="font-">DeltaQ's</span> dedicated team of professionals, well-versed in <span className="font-">HVAC technology</span>, meticulously designs and implements systems that not only meet industry standards but exceed client expectations. From conceptualization to implementation, our rigorous testing processes guarantee the reliability and durability of every <span className="font-">HVAC system</span> we deliver.
        <br/>
        <br/>
        In the realm of <span className="font-semibold">Cold Rooms</span>, <span className="font-">DeltaQ</span> stands as an innovator, specializing in the design and implementation of these crucial spaces. Particularly vital in industries where precise temperature control is mission-critical, our <span className="font-semibold">cold room solutions</span> are crafted with precision and expertise. Whether storing pharmaceuticals, food products, or other temperature-sensitive materials, <span className="font-">DeltaQ's cold rooms</span> are tailor-made to preserve the integrity of stored items. Our commitment to excellence and reliability extends to every aspect of <span className="font-semibold">cold room construction</span>. With sustainability at the forefront, we integrate eco-friendly practices into our designs, minimizing environmental impact while delivering top-notch solutions. <span className="font-">DeltaQ's client-centric approach</span> ensures that our <span className="font-">cold room solutions</span> are customized to meet the unique requirements of each project, fostering lasting relationships and satisfaction among our clientele.

        </p>
      </div>
      </div>

<div data-aos="fade-up">

      <div className=' text-center w-full flex justify-center pt-10'>
      <h1 className='font-anton md:text-6xl text-3xl border-b-4 pb-4 md:pb-10  md:w-1/2 border-[#3466AA] '>CUSTOMER-CENTRIC APPROACH</h1>
        
      </div>

      <div className='text-center text-[#3466AA]  flex justify-center px-8 md:px-0 pt-10 pb-10'>
        <p className='font-poppins font-light pb-4 md:w-7/12 md:text-base text-sm  mb-24 md:mb-32 text-justify'>Our unwavering commitment to a <span className="font-semibold">customer-centric approach</span> defines the essence of <span className="-semibold">DeltaQ's</span> philosophy. At the core of our operations lies the understanding that each project is inherently unique, with distinct requirements and objectives. We pride ourselves on cultivating strong partnerships with our clients, acknowledging that their success is integral to our own.
        <br/>
        <br/>
The journey begins with the initial consultation, a crucial phase where we delve into the intricacies of the project, gaining a profound understanding of our client's needs and expectations. This collaborative process sets the stage for the development of <span className="font-semibold">bespoke solutions</span>, crafted with precision and tailored to the specific requirements of each project. We recognize that effective communication is paramount, and our team ensures a transparent and open dialogue at every step of the way.
<br/>
        <br/>
As the project unfolds, we remain dedicated to providing ongoing support, underscoring our commitment to the long-term success of our clients. This <span className="font-semibold">customer-centric ethos</span> extends beyond the completion of a project; it becomes a lasting partnership where we continue to evolve our solutions in alignment with changing needs. At <span >DeltaQ</span>, our client relationships are not just transactions; they are enduring collaborations founded on trust, transparency, and a shared commitment to achieving excellence.</p>

      </div>
</div>





    </div>
  )
}