import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/deltaQ_logo_horz.png";
import burger from "../images/navbar_burger.png";
import navbar_header from "../images/navbar_header.png";

export default function Navbar() {

  const [isExpanded,setIsExpanded]=useState(false)

  return (<>
    <div>
    <div className='fixed z-10 top-0 bg-[#fafafa] w-full'>
      <img src={navbar_header} className='w-full h-1.5'></img>
      <div className='flex  justify-between items-center py-3.5 px-2 md:px-52'>
        <Link to="/">
        <img src={logo} className='h-12'></img>
        </Link>
        <div className='md:flex space-x-9  items-center '>
          <Link to="/"className='hidden md:block font-poppins hover:text-[#3466AA] focus:text-[#3466AA] font-bold text-sm transition-all'>Home</Link>
          <Link to="/services"className='hidden md:block font-poppins hover:text-[#3466AA] focus:text-[#3466AA] font-bold text-sm transition-all '>Services</Link>
          <Link to="/contact-us"className='hidden md:block font-poppins hover:text-[#3466AA] focus:text-[#3466AA] font-bold text-sm transition-all '>Contact Us</Link>
          <Link to="/about-us"className='hidden md:block font-poppins hover:text-[#3466AA] focus:text-[#3466AA] font-bold text-sm transition-all '>About Us</Link>
          <button className='md:hidden' onClick={()=>(setIsExpanded((prev)=>(!prev)))}>
          <img src={burger} className=''></img>
          </button>
        </div>
      </div>
    </div>
    <div className='m-18'>
    </div>
    </div>
    <div className={`z-20 md:hidden w-full  ${!isExpanded && " translate-x-full"} transition-all duration-300 h-full fixed bg-[#3466AA]`}>
      <button className='fixed right-0 p-4 ' onClick={()=>(setIsExpanded((prev)=>(!prev)))}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 24 24" style={{ fill: 'white' }}>
          <path d="M11 0.7H13V23.3H11z" transform="rotate(-45.001 12 12)"></path>
          <path d="M0.7 11H23.3V13H0.7z" transform="rotate(-45.001 12 12)"></path>
        </svg>
      </button>


      <div className='items-center h-full justify-center space-y-7 flex flex-col uppercase'>
                <Link to="/" onClick={()=>(setIsExpanded((prev)=>(!prev)))} className='font-poppins font-light text-white text-2xl'>Home</Link>
                <Link to="/services" onClick={()=>(setIsExpanded((prev)=>(!prev)))} className='font-poppins font-light text-white text-2xl'>Services</Link>
                <Link to="/contact-us" onClick={()=>(setIsExpanded((prev)=>(!prev)))} className='font-poppins font-light text-white text-2xl'>Contact Us</Link>
                <Link to="/about-us" onClick={()=>(setIsExpanded((prev)=>(!prev)))} className='font-poppins font-light text-white text-2xl'>About Us</Link>
      </div>
    </div>
    </>
  )
}

