import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/footer_logo.png';
import facebook from '../images/facebook_icon.png';
import instagram from '../images/instagram_icon.png';
import linkedin from '../images/linkedin_icon.png';

export default function footer() {
  return (
    <div className='w-full z-10 p-5 pb-0 md:px-14 px-6 bg-[#1C1C1C]'>
      <div className='md:flex justify-between pb-4 md:px-8 md:pr-4 border-b-2 border-white border-opacity-50'>
      <div className='md:w-1/3'>
      <img className='h-32 object-contain' src={logo}></img>
      <p className='text-white py-6 md:py-8 font-poppins font-thin md:font-extralight'>Mastering Comfort, Redefining Spaces: Your Trusted Partner for Superior Heating and Cooling Solutions.</p>
      </div>
      <div className='md:w-1/4 md:p-4 h-full space-x-8 flex justify-evenly'>
        <div className='flex-col space-y-4 w-60'>
          <h2 className='font-poppins font-bold text-white '>Menu</h2>
          <div>
            <Link to="/"className=' text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all'>Home</Link>
          </div>
          <div>
          <Link to="/services"className=' text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all '>Services</Link>  
          </div>
          <div>
          <Link to="/contact-us"className=' text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all '>Contact Us</Link>
          </div>
          <div>
          <Link to="/about-us"className=' text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all '>About Us</Link>
          </div>
        </div>
        <div className='flex-col space-y-4'>
          <h2 className='font-poppins font-bold text-white '>Contact Us</h2>
          {/* <h3 className='text-white opacity-50 text md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all'>Mig 845, First Floor Ganapathy Maa Nagar, Ganapahy Coimbatore - 641006</h3> */}
          <h3 className='text-white opacity-50 text md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all'>TC-16/155 Vijaya Plaza, First Floor, Ulloor, Akkulam Road, Medical College Thiruvananthapuram 695011</h3>
          <h3 className='text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all '>Sales@deltaq.in</h3>
          <h3 className='text-white opacity-50 md:block font-poppins hover:text-[#ffffffc5] focus:text-[#ffffffc5] font-bold text-sm transition-all '>TEL: +91 7736791996</h3>
        </div>
      </div>
      </div>
      <div className='flex py-2 md:px-8 opacity-75 justify-between'>
        <p className='text-white opacity-75  font-poppins font-extralight'>Copyright @DeltaQ</p>
        <div className='flex space-x-2  items-center'>
          <p className='text-white  opacity-75  font-poppins font-extralight'>Follow us on</p>
          <img src={facebook} className='object-cover opacity-75  hover:opacity-100 transition-all h-5'></img>
          <img src={linkedin}className='object-cover opacity-75 hover:opacity-100 transition-all h-5'></img>
          <a href="https://www.instagram.com/deltaqsolutions/" target="_blank">
            <img src={instagram} className='object-cover opacity-75 hover:opacity-100 transition-all h-5' />
          </a>

        </div>
      </div>
    </div>
  )
}
